export default {
  back: 'Back',
  products: 'Products',
  home: {
    top: {
      title: 'Safe, private and valuable services',
      desc: 'Apps and features to help you turn ideas into reality, stay safer, and focus on the important things in work.',
      btn: 'Explore Productions',
    },
    key: {
      title: 'Keep your private key with Mornin Key',
      desc: 'Mornin Key Password Manager is the easiest way to store and use strong passwords. Log in to sites and fill forms securely with a single click.',
    },
    zone: {
      title: 'Let work flow with Mornin Zone',
      desc: 'Mornin Zone allows your team to work in security and privacy, from project management, employee management to company contract issuance and even salary payment, etc.',
    },
    qa: {
      title: 'Frequently Asked Questions',
      list: [
        {
          q: 'What kind of enterprise software company is Mornin?',
          a: 'Mornin is an enterprise software company focused on security and privacy, providing security products such as password managers and enterprise collaboration software for businesses and individuals.',
        },
        {
          q: 'What is Mornin Key?',
          a: `Mornin Key is a password management software that helps users save and manage passwords for various accounts, protecting users' account information.`,
        },
        {
          q: 'What is Mornin Zone?',
          a: 'Mornin Zone is an enterprise collaboration software that helps internal employees collaborate online, manage tasks, and receive message notifications.',
        },
        {
          q: `What are the characteristics of Mornin's products?`,
          a: `Mornin's products have multiple characteristics, including security, privacy, ease of use, and intelligence, which can meet users' needs.`,
        },
        {
          q: `How is Mornin's product different from other password management software?`,
          a: `Mornin's password management software is based on cloud technology and has multiple features, such as security, ease of use, and intelligence, which can effectively protect user data security and privacy.`,
        },
        {
          q: 'How does Mornin Key ensure the security of user data?',
          a: 'Mornin Key uses advanced encryption technology and multi-factor authentication to protect user data and prevent data leakage and malicious attacks.',
        },
        {
          q: 'How does Mornin Zone help businesses improve work efficiency?',
          a: 'Mornin Zone can help enterprise employees collaborate online, communicate in real-time, and quickly complete tasks, improving work efficiency and quality.',
        },
        {
          q: `How is Mornin's product priced?`,
          a: `Mornin's product price is based on the number of users and duration of use and can be flexibly selected according to the needs of the enterprise.`,
        },
        {
          q: 'Does Mornin provide free trial services?',
          a: `Mornin provides a limited free trial service, allowing users to experience Mornin's products and services during the trial period and decide whether to purchase.`,
        },
        {
          q: `How is Mornin's customer service support?`,
          a: 'Mornin provides various customer service support methods, including online customer service, phone customer service, email customer service, and other methods, which can effectively help users solve problems.',
        },
      ],
    },
    soon: {
      title: 'Mornin is launching soon',
      desc: 'You will receive emails about One Mornin Ltd, which include about Mornin Key, Mornin Zone, and new products.',
      name: 'Enter your name',
      address: 'Enter your email address',
      btn: 'Join the waitlist',
    },
  },
  support: {
    top: {
      title: 'The security first password manager',
      desc: 'Mornin Key gives you the confidence to protect your accounts and collaborate securely - without the hassle. Backed by proven end-to-end encryption, security is now convenient.',
    },
    why: {
      title: 'Why Mornin Key?',
      list: [
        {
          title: 'Always private, always secure',
          desc: `Our patented zero-knowledge architecture ensures that we never see your data. And if you stop using Mornin Key, you’ll take your organization's data with you.`,
        },
        {
          title: 'Simple to deploy, easy to Use',
          desc: `Your privacy is our top priority. A combination of policy, innovative thinking, and a deep respect for your right to privacy ensure that your data is always kept safe and secure.`,
        },
        {
          title: 'Complete end-to-end protection',
          desc: 'Mornin Key continuously scans 20+ billion breach and hack records to ensure no threat goes undetected, and 2-factor authentication offers an additional layer of protection.',
        },
        {
          title: 'Proactive monitoring and remediation',
          desc: 'Simple remediation tools empower admins to proactively discover risky behavior and alert employees on breached and hacked accounts.',
        },
      ],
    },
    manager: {
      title: 'The best-in-class password manager',
      info: ['We’ve never been breached', 'We don’t see your data', 'You take your data if you leave'],
    },
    use: {
      title: 'Use Mornin Key Now',
      desc: 'Secure, Privacy and easy-to-use password manager.',
    },
  },
  product: {
    top: {
      title: 'Private platform for you team and your work',
      desc: 'Mornin Zone allows your team to work in security and privacy, from project management, employee management to company contract issuance and even salary payment, etc.',
    },
    feature: {
      title: 'Features',
      list: [
        {
          icon: '',
          title: 'No Address Book Access Required',
          desc: 'Mornin Zone can be used without granting access to the local address book. Simply create separate lists for different user groups.',
        },
        {
          icon: '',
          title: 'Pre-Configuration of the app',
          desc: 'The Mornin Zone app can be pre-configured to an extent that it’s ready for use out of the box, and end users don’t need to complete a setup first.',
        },
        {
          icon: '',
          title: 'Mornin Zone Work Directory',
          desc: 'Mornin Key continuously scans 20+ billion breach and hack records to ensure no threat goes undetected, and 2-factor authentication offers an additional layer of protection.',
        },
        {
          icon: '',
          title: 'MDM Support',
          desc: 'Mornin Zone Work supports all common MDM systems, and in case your company doesn’t use an MDM system, Mornin Zone MDM serves as an equivalent alternative.',
        },
        {
          icon: '',
          title: 'Usage Analytics',
          desc: 'Find out how Mornin Zone Work is used in your company thanks to various graphs and stats in the management cockpit.',
        },
        {
          icon: '',
          title: 'Active Directory Integration',
          desc: 'For large corporations, a GUI might not be ideal for administration, which is why Mornin Zone Work offers various interfaces (APIs) that can be used for automation purposes.',
        },
      ],
    },
  },
  key: {
    top: {
      title: 'Decentralized  Key Manager',
      desc: 'Mornin Key is an open source decentralized key manager that helps users truly control and protect their data through strong encryption technology and decentralized design concepts.',
    },
    feature: {
      title: 'Why Mornin Key?',
      list: [
        {
          title: 'Decentralization',
          desc: `Your account key and data are not stored on Mornin Key's server, it is anywhere you want, and you can even continue to access your data in any other products that support decryption.`,
        },
        {
          title: 'Open source',
          desc: 'In order to improve transparency and trust, Mornin Key discloses the source code of Android and iOS applications. Anyone can audit the code to understand the logic and algorithms behind the software.',
        },
        {
          title: 'Own your data',
          desc: 'Your encrypted data can be stored in centralized networks such as iCloud and Dropbox, or in decentralized storage networks such as Arweave and IPFS.',
        },
        {
          title: 'Data encryption',
          desc: 'All your data is asymmetrically encrypted with the account key, and neither we nor anyone else can view the content you store in Mornin Key.',
        },
        {
          title: 'Multiple accounts',
          desc: 'Support adding or importing multiple accounts, each account has an independent account key, and the data between accounts is isolated from each other and stored independently.',
        },
        {
          title: 'Unlimited storage',
          desc: 'You can store unlimited key, login and password data, and access these encrypted data safely and reliably on any device.',
        },
      ],
    },

    qa: {
      title: 'Frequently Asked Questions',
      desc: 'Find answers to some of the most common questions.',
      list: [
        {
          q: 'What is a key manager?',
          a: `The key manager is a program used to generate and store encryption keys. It can generate high-strength random keys, encrypt and store sensitive information, perform data verification and signature, etc., and can effectively avoid key leakage or abuse.`,
        },
        {
          q: 'How does the Mornin Key work?',
          a: `The account master key locally encrypts and stores data items, and can optionally synchronize encrypted data to network storage or other devices. Mornin Key will not store your account master key and encrypted data, only you can access your encrypted data items.`,
        },
        {
          q: `Is the Mornin Key safe?`,
          a: `Very safe! Even if Mornin Key is hacked, your data is still safe, all the data you save in Mornin Key can only be unlocked by your master key, we do not store or transmit your master key.`,
        },
        {
          q: `Do I need an internet connection to use Mornin Key?`,
          a: `You don't need internet access to use Mornin Key. While backing up encrypted data to iCloud, Arweave, etc. requires a network connection, a local copy is kept on the device for quick access, so you can use Mornin Key offline.`,
        },
        {
          q: `Can my data be decrypted if the Mornin Key app can't be opened?`,
          a: 'The Mornin Key master key is a mature asymmetric encryption scheme. You can import the mnemonic phrase into other apps that support standard key encryption and decryption to obtain a private key, and then use the private key to decrypt your data.',
        },
      ],
    },
    use: {
      title: 'Use Mornin Key Now',
      desc: 'Secure, Privacy and easy-to-use password manager.',
    },
  },
  zone: {
    top: {
      title: 'Private platform for you team and your work',
      desc: 'Mornin Zone allows your team to work in security and privacy, from project management, employee management to company contract issuance and even salary payment, etc.',
      btn: 'Contact sales',
    },
    feature: {
      title: 'Features',
      list: [
        {
          title: 'No Address Book Access Required',
          desc: 'Mornin Zone can be used without granting access to the local address book. Simply create separate lists for different user groups.',
        },
        {
          title: 'Pre-Configuration of the app',
          desc: 'The Mornin Zone app can be pre-configured to an extent that it’s ready for use out of the box, and end users don’t need to complete a setup first.',
        },
        {
          title: 'Mornin Zone Work Directory',
          desc: 'Mornin Key continuously scans 20+ billion breach and hack records to ensure no threat goes undetected, and 2-factor authentication offers an additional layer of protection.',
        },
        {
          title: 'MDM Support',
          desc: 'Mornin Zone Work supports all common MDM systems, and in case your company doesn’t use an MDM system, Mornin Zone MDM serves as an equivalent alternative.',
        },
        {
          title: 'Usage Analytics',
          desc: 'Find out how Mornin Zone Work is used in your company thanks to various graphs and stats in the management cockpit.',
        },
        {
          title: 'Active Directory Integration',
          desc: 'For large corporations, a GUI might not be ideal for administration, which is why Mornin Zone Work offers various interfaces (APIs) that can be used for automation purposes.',
        },
      ],
    },
    plan: {
      title: 'Flexible plans for every team',
      per: 'per month',
      essential: {
        name: 'Startup',
        desc: 'For companies with only a few users and low demands on business features',
        price: '$100',
        list: ['All app features', 'Basic Support', 'Max. number of licenses: <b>10</b>'],
      },
      professional: {
        name: 'Professional',
        desc: 'For companies with high standards regarding customization, administration, and automation',
        price: '$1000',
        list: ['All app features', 'MDM', 'Broadcast', 'APIs', 'Max. number of licenses: <b>100</b>', 'Standard Support'],
      },
      advanced: {
        name: 'Enterprise',
        desc: 'For companies with low requirements in terms of customization, administration, and automation',
        price: 'Custom',
        list: ['All app features', 'Management cockpit', 'MDM', 'Broadcast', 'APIs', 'Max. number of licenses: <b>Unlimited</b>', 'Premium Support'],
      },
      buy: 'Buy Now',
      btn: 'Contact Sales',
    },
  },
  footer: {
    product: 'Products',
    key: 'Mornin Key',
    zone: 'Mornin Zone',
    support: 'Support',
    help: 'Help Center',
    contact: 'Contact US',
    kit: 'Press Kit',
    legal: 'Legal',
    privacy: 'Privacy Police',
    terms: 'Terms of Use',
  },
  testFight: `Free on TestFlight`,
  apk: `Download APK`,
  btn: {
    more: 'Learn More',
  },
  contact: {
    title: 'Contact Us',
    desc: `If you have any questions, please reach out to us directly, we'd be happy to give you more details.`,
    fullName: 'Name(required)',
    email: 'Email(required)',
    businessType: 'Business Type(required)',
    businessTypeMap: {
      food: 'Food & Beverages',
      retail: 'Retail',
      services: 'Professional Services',
      education: 'Education',
      media: 'Media',
      tech: 'Tech & Startups',
      other: 'Other',
    },
    message: 'Message(required)',
    submit: 'Submit',
    confirm: 'Confirm',
  },

  toast: {
    submit: {
      success: 'Submit successfully.',
      fail: 'Submit failed.',
    },
  },
};
