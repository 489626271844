import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue';
import Support from '../views/Support.vue';
import Product from '../views/Product.vue';
import Key from '../views/Key.vue';
import Zone from '../views/Zone.vue';
import Contact from '../views/Contact.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/support',
    name: 'support',
    component: Support,
  },
  {
    path: '/product',
    name: 'product',
    component: Product,
  },
  {
    path: '/key',
    name: 'key',
    component: Key,
  },
  {
    path: '/zone',
    name: 'zone',
    component: Zone,
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
